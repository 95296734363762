import { memo, useEffect, useState } from "react";
import { KeyedMutator } from "swr";
import { aggregate, formatNumber } from "@/lib/util";
import Loader from "@/components/CustomUiComponents/Loader";
import { Badge, Flex, Heading, IconButton, Tabs, Text } from "@radix-ui/themes";
import NewMultiSelect from "../../CustomUiComponents/NewMultiSelect";
import MassPostImport from "../../Modals/NewMassImport";
import NewCreateReport from "../../Modals/NewCreateReport";
import NewShareLink from "../../Modals/NewShareLink";
import Playlists from "../Playlists";
import PostsTab from "./PostsTab";
import SoundsTab from "./SoundsTab";
import CreatorsTab from "./CreatorsTab";
import NewAddBudget from "@/components/Modals/NewAddBudget";
import SettingsTab from "./SettingsTab";
import useMobile from "@/lib/useMobile";
import { useCreatorTags } from "@/services/creator";
import { useCampaignBudgets } from "@/services/campaign";
import { IconReload, IconX } from "@tabler/icons-react";
import { useRouter } from "next/router";
import DateFilter from "@/components/CustomUiComponents/DateFilter";
import useUser from "@/lib/useUser";
import fetchJson from "@/lib/fetchJson";
import DeleteCampaign from "@/components/Modals/DeleteCampaign";

const Totals = ({ title, value }: { title: string; value: string }) => (
  <Flex direction="column" gap="2">
    <Text size="3">{title}</Text>
    <Heading size="8">{value}</Heading>
  </Flex>
);

interface Props {
  editable: boolean;
  shareable: boolean;
  reportable: boolean;
  transferable: boolean;
  data: Campaign | undefined;
  mutate: KeyedMutator<Campaign>;
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const AdvancedView = ({
  data,
  editable,
  mutate,
  shareable,
  transferable,
  reportable,
}: Props) => {
  const ALL_TYPES = ["Tiktok", "Instagram", "Twitter", "Youtube"];
  const DEFAULT_TYPES = ["Tiktok", "Instagram"];
  const { data: creatorTags } = useCreatorTags();
  const { user, checkRoles } = useUser();
  const { data: campaignBudgets } = useCampaignBudgets(data?.id as any);
  const [types, setTypes] = useState<string[]>(ALL_TYPES);
  const [sortKey, setSortKey] = useState("views");
  const [sortDir, setSortDir] = useState("desc");
  const [tags, setTags] = useState([] as string[]);
  const [selectedTags, setSelectedTags] = useState([] as string[]);
  const [selectedPostTags, setSelectedPostTags] = useState([] as string[]);
  const [filterDates, setFilterDates] = useState<Value>([null, null]);
  const [posts, setPosts] = useState([]);
  const [scraping, setScraping] = useState(false);
  const { isMobile } = useMobile();
  const router = useRouter();
  const isAdmin = checkRoles(["agency-admin", "superadmin"]);

  const playlists = data?.post?.filter(
    ({ type }) => type === "spotify-playlist"
  );

  const currentTags = Array.from(new Set(data?.tags)).sort();
  if (JSON.stringify(tags) != JSON.stringify(currentTags)) {
    setTags(currentTags);
  }

  useEffect(() => {
    setPosts(
      data?.post
        ?.filter(({ type }) =>
          ["instagram", "tiktok", "twitter", "youtube"].includes(type)
        )
        .filter(
          (post) =>
            !selectedPostTags.length ||
            post.tags.some((tag) => selectedPostTags.includes(tag))
        )
        .filter((post) => types.map((i) => i.toLowerCase()).includes(post.type))
        .filter((post) => {
          const postedAt = new Date(post.postedAt);
          let valid = true;
          if (filterDates === null) {
            return true;
          }
          if (filterDates instanceof Date) {
            return postedAt > filterDates;
          }
          if (filterDates[0] !== null && postedAt <= filterDates[0]) {
            valid = false;
          }
          if (filterDates[1] !== null && postedAt >= filterDates[1]) {
            valid = false;
          }
          return valid;
        })
    );
  }, [data?.post, filterDates, types, selectedPostTags]);

  console.log("filterDates", filterDates, posts);

  const audio = data?.post.filter(({ type }) =>
    ["instagram-audio", "tiktok-audio"].includes(type)
  );
  const hashtags = data?.hashtags;
  // const creatorTypeInit = data.budgets
  //   .map((i) => i.creatorPrice.creator.creatorType)
  //   .filter((i) => i)
  //   .map((i) => ({ value: i.id, text: i.tag }));
  // let creatorTypes = [] as any;
  // for (let i = 0; i < creatorTypeInit.length; i++) {
  //   if (!types.find((x: any) => x.value === creatorTypeInit[i].value)) {
  //     creatorTypes.push(creatorTypeInit[i]);
  //   }
  // }

  const forceScrapePosts = async () => {
    setScraping(true);
    await fetchJson(`/campaign/${data.id}/scrape`, {
      method: "POST",
    });
    setScraping(false);
    alert(
      "Posts are queued to be re-scraped, please wait 10 minutes before checking again"
    );
  };

  if (!data) {
    return <Loader full={false} />;
  }

  return (
    <>
      <Flex direction="row" justify="between" gap="4" pb="4">
        <Flex direction="row" gap="2" align="center">
          <Heading size="6">Campaign Report</Heading>
        </Flex>
        <Flex gap="5">
          {isAdmin && (
            <IconButton
              variant="ghost"
              onClick={forceScrapePosts}
              disabled={scraping}
            >
              <IconReload className="icon" />
            </IconButton>
          )}
          {reportable && (
            <NewCreateReport
              campaign={data}
              tags={selectedPostTags}
              sortDir={sortDir}
              sortKey={sortKey}
              numPosts={posts?.length || 0}
              types={types}
            />
          )}
          {shareable && (
            <NewShareLink
              id={`${window.location.protocol}//${window.location.hostname}/share/${data.shareCode}`}
            />
          )}
          {isAdmin && <DeleteCampaign campaignId={data.id} />}
        </Flex>
      </Flex>
      <Tabs.Root defaultValue="posts">
        <Tabs.List size="2">
          <Tabs.Trigger value="posts">
            <Flex direction="row" gap="1">
              Posts
              <Badge>{posts && posts.length}</Badge>
            </Flex>
          </Tabs.Trigger>
          <Tabs.Trigger value="tracking">
            <Flex direction="row" gap="1">
              Tracking
              <Badge>{(audio?.length || 0) + (hashtags?.length || 0)}</Badge>
            </Flex>
          </Tabs.Trigger>
          {/* TODO: Fix/finish post drafts */}
          {/* <Tabs.Trigger value="postdrafts">
            <Flex direction="row" gap="1">
              Post Drafts
              <Badge>{data.postDrafts && data.postDrafts.length}</Badge>
            </Flex>
          </Tabs.Trigger> */}
          {editable && (
            <Tabs.Trigger value="creators">
              <Flex direction="row" gap="1">
                Creators
                <Badge>{data.budgets && data.budgets.length}</Badge>
              </Flex>
            </Tabs.Trigger>
          )}
          <Tabs.Trigger value="playlists">
            <Flex direction="row" gap="1">
              Playlists
              <Badge>{playlists && playlists.length}</Badge>
            </Flex>
          </Tabs.Trigger>
          <Tabs.Trigger value="settings">Settings</Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value="posts">
          <Flex direction="column" gap="5" pt="4">
            <Flex className="box" gap="5" p="5" direction="column">
              <Flex direction="row" gap="2" justify="between" align="center">
                <Heading size="4">Post Analytics</Heading>
                <Flex id="dashboard-analytics-filters" direction="row" gap="3">
                  <DateFilter
                    setFilterDates={setFilterDates}
                    filterDates={filterDates}
                  />

                  <NewMultiSelect
                    transparent
                    style={{ width: 150, marginRight: 10 }}
                    setSelected={setSelectedPostTags}
                    title="Tags"
                    selected={selectedPostTags}
                    options={Array.from(new Set(data?.tags)).toSorted() as any}
                  />
                  <NewMultiSelect
                    transparent
                    style={{ width: 140, marginRight: 10 }}
                    setSelected={setTypes}
                    title="Platform"
                    selected={types}
                    options={ALL_TYPES}
                  />
                </Flex>
              </Flex>
              <Flex direction="row" gap="2" justify="between">
                <Totals
                  title="Views"
                  value={formatNumber(aggregate(posts, "views"))}
                />
                <Totals
                  title="Likes"
                  value={formatNumber(aggregate(posts, "likes"))}
                />
                <Totals
                  title="Shares"
                  value={formatNumber(aggregate(posts, "shares"))}
                />
                <Totals
                  title="Comments"
                  value={formatNumber(aggregate(posts, "comments"))}
                />
              </Flex>
            </Flex>
            <PostsTab
              fullPosts={posts || []}
              mutate={mutate}
              editable={editable}
              id={data?.id}
            />
          </Flex>
        </Tabs.Content>

        <Tabs.Content value="tracking">
          <Flex direction="column" pt="4" gap="3">
            <Flex
              direction="row"
              justify="between"
              pl="1"
              px={isMobile ? "5" : ""}
            >
              <Heading size="4">Tracking - Sounds & Hashtags</Heading>
              {editable && (
                <MassPostImport
                  type="sounds"
                  mutate={mutate}
                  campaignId={data.id}
                />
              )}
            </Flex>
            <Flex className="box" direction="column" p="5">
              <SoundsTab
                audio={audio || []}
                hashtags={hashtags || []}
                mutate={mutate}
                editable={editable}
              />
            </Flex>
          </Flex>
        </Tabs.Content>

        {/* <Tabs.Content value="postdrafts">
          <Flex direction="column" pt="4" gap="3">
            <Flex direction="row" justify="between" pl="1">
              <Heading size="4">Post Drafts</Heading>
              {editable && <NewAddDraft mutate={mutate} campaignId={data.id} />}
            </Flex>
            <Flex className="box" direction="column" p="5">
              <DraftsTab
                drafts={data.postDrafts}
                mutate={mutate}
                editable={editable}
                id={data.id} />
            </Flex>
          </Flex>
        </Tabs.Content> */}

        {editable && (
          <Tabs.Content value="creators">
            <Flex direction="column" pt="4" gap="3">
              <Flex direction="row" justify="between" pl="1">
                <Flex direction="row" justify="between" pl="1" align={"center"}>
                  <Heading size="4">Creators</Heading>
                  <NewMultiSelect
                    transparent
                    style={{ width: 140, marginRight: 10 }}
                    setSelected={setTypes}
                    title="Platform"
                    selected={types}
                    options={ALL_TYPES}
                  />
                  <NewMultiSelect
                    transparent
                    style={{ width: 150, marginRight: 10 }}
                    setSelected={setSelectedTags}
                    title="Post Formats"
                    selected={selectedTags}
                    options={
                      Array.from(
                        (
                          new Set(creatorTags?.map((t) => t.id)) as any
                        ).intersection(
                          new Set(campaignBudgets?.map((b) => b.creatorTypeId))
                        )
                      )
                        .map((b) => creatorTags?.find((t) => t.id === b)?.tag)
                        .toSorted() as any
                    }
                  />
                  {selectedTags.map((tag) => {
                    return (
                      <Badge radius="large" key={tag} mr="1">
                        {tag}
                        <IconButton
                          variant="ghost"
                          onClick={() =>
                            setSelectedTags(
                              [...selectedTags].filter((t) => t !== tag)
                            )
                          }
                        >
                          <IconX className="icon-accent" height="10px" />
                        </IconButton>
                      </Badge>
                    );
                  })}
                </Flex>
                <NewAddBudget mutate={mutate} campaignId={data.id} />
              </Flex>
              <CreatorsTab
                platforms={types}
                campaign={data}
                mutate={mutate}
                selectedTags={selectedTags}
              />
            </Flex>
          </Tabs.Content>
        )}

        <Tabs.Content value="playlists">
          <Flex direction="column" pt="4">
            <Flex className="box" p="5">
              <Playlists
                fullPlaylists={playlists}
                spotifyPlaylists={JSON.parse(data?.song?.playlists || "{}")}
              />
            </Flex>
          </Flex>
        </Tabs.Content>

        <Tabs.Content value="linkpages"></Tabs.Content>

        <Tabs.Content value="settings">
          <SettingsTab
            transferable={transferable}
            campaign={data}
            mutate={mutate}
          />
        </Tabs.Content>
      </Tabs.Root>
    </>
  );
};
export default memo(AdvancedView);
