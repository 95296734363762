import { Button, Flex, Table, TextField } from "@radix-ui/themes";
import MobilePostListRow from "./MobilePostsListRow";
import { KeyedMutator } from "swr";
import { useEffect, useMemo, useState } from "react";
import {
  IconChevronLeft,
  IconChevronRight,
  IconSearch,
} from "@tabler/icons-react";

interface PostsListViewProps {
  fullPosts: Post[];
  mutate?: KeyedMutator<Campaign>;
  editable: boolean;
}
const MobilePostsListView = ({
  fullPosts,
  mutate,
  editable,
}: PostsListViewProps) => {
  const [page, setPage] = useState(0);
  const PAGE_SIZE = 10;
  const [search, setSearch] = useState("");
  const searched = fullPosts?.filter(
    (i: any) =>
      i.username?.toLowerCase().includes(search.toLowerCase()) ||
      i.url?.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    setPage(0);
  }, [search, fullPosts]);

  return (
    <Flex direction="column" gap="3">
      <TextField.Root
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search posts..."
        radius="medium"
        variant="soft"
        style={{ fontSize: "16px" }}
      >
        <TextField.Slot pr="4">
          <IconSearch className="icon-dark" width="20px" />
        </TextField.Slot>
      </TextField.Root>
      <Table.Root size="1">
        <Table.Body>
          {searched &&
            searched
              .slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
              .map((post) => (
                <Table.Row align="center" key={post.id}>
                  <Table.Cell>
                    <MobilePostListRow
                      mutate={mutate || null}
                      post={post}
                      editable={editable}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
        </Table.Body>
      </Table.Root>
      <Flex direction="row" gap="3" justify="center">
        <Button
          variant="ghost"
          disabled={page === 0}
          onClick={() => setPage((prev: any) => prev - 1)}
        >
          <IconChevronLeft className="icon-accent" />
          Previous
        </Button>
        <Button
          variant="ghost"
          disabled={page >= searched?.length / PAGE_SIZE - 1}
          onClick={() => setPage((prev: any) => prev + 1)}
        >
          Next
          <IconChevronRight className="icon-accent" />
        </Button>
      </Flex>
    </Flex>
  );
};

export default MobilePostsListView;
