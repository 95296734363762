import { memo, useEffect, useMemo, useState } from "react";
import { KeyedMutator } from "swr";
import { aggregate, capitalize, formatNumber } from "@/lib/util";
import Loader from "@/components/CustomUiComponents/Loader";
import {
  Badge,
  Button,
  Flex,
  Heading,
  IconButton,
  Tabs,
  Text,
} from "@radix-ui/themes";
import NewMultiSelect from "../../CustomUiComponents/NewMultiSelect";
import MassPostImport from "../../Modals/NewMassImport";
import NewCreateReport from "../../Modals/NewCreateReport";
import NewShareLink from "../../Modals/NewShareLink";
import Playlists from "../Playlists";
import NewMassImport from "../../Modals/NewMassImport";
import NewAddDraft from "@/components/Modals/NewAddDraft";
import NewAddBudget from "@/components/Modals/NewAddBudget";
import SoundsTab from "./SoundsTab";
import DraftsTab from "./DraftsTab";
import CreatorsTab from "./CreatorsTab";
import SettingsTab from "./SettingsTab";
import { MobileTotals } from "../DashboardView/MobileDashboardView";
import MobilePostsListView from "../Posts/MobilePostsListView";
import { IconArrowsSort, IconDownload, IconX } from "@tabler/icons-react";
import { useCreatorTags } from "@/services/creator";
import { useCampaignBudgets } from "@/services/campaign";
import DateFilter from "@/components/CustomUiComponents/DateFilter";
import { CSVLink } from "react-csv";
import * as Dialog from "@/components/CustomUiComponents/Dialog";
import ClearableSelect from "@/components/CustomUiComponents/ClearableSelect";

interface Props {
  editable: boolean;
  shareable: boolean;
  reportable: boolean;
  transferable: boolean;
  data: Campaign | undefined;
  mutate: KeyedMutator<Campaign>;
}

interface EditSortProps {
  sortKey: string;
  setSortKey: any;
  sortDir: string;
  setSortDir: any;
}
const EditSort = ({
  sortKey,
  setSortKey,
  sortDir,
  setSortDir,
}: EditSortProps) => {
  const sortDirOptions = [
    { label: "Ascending", value: "asc" },
    { label: "Descending", value: "desc" },
  ];
  const sortKeyOptions = [
    { label: "Post Date", value: "postedAt" },
    { label: "Author", value: "username" },
    { label: "Views", value: "views" },
    { label: "Likes", value: "likes" },
    { label: "Shares", value: "shares" },
    { label: "Comments", value: "comments" },
  ];

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <IconButton variant="outline">
          <IconArrowsSort className="icon-accent" />
        </IconButton>
      </Dialog.Trigger>
      <Dialog.Overlay />
      <Dialog.Content bottom style={{ maxWidth: "100vw" }}>
        <Dialog.Title>Sort Posts</Dialog.Title>
        <Flex direction="column" gap="5" justify="start" width="100%">
          <Flex direction="row" gap="2" align="center">
            <Flex direction="row" width="40vw">
              <Text as="div" size="2" mb="1" weight="bold">
                Sort by
              </Text>
            </Flex>
            <ClearableSelect
              value={sortKey}
              onValueChange={setSortKey}
              placeholder="Choose..."
              options={sortKeyOptions.map((key) => ({
                label: key.label,
                value: key.value,
              }))}
            />
          </Flex>
          <Flex direction="row" gap="2" align="center">
            <Flex direction="row" width="40vw">
              <Text as="div" size="2" mb="1" weight="bold">
                Sort Direction
              </Text>
            </Flex>
            <ClearableSelect
              value={sortDir}
              onValueChange={setSortDir}
              placeholder="Choose..."
              options={sortDirOptions.map((dir) => ({
                label: dir.label,
                value: dir.value,
              }))}
            />
          </Flex>
        </Flex>

        <Flex direction="column" gap="3" justify="end" width="100%">
          <Dialog.Close>
            <Button variant="outline" onClick={() => {}}>
              Close
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
const MobileAdvancedView = ({
  data,
  editable,
  mutate,
  shareable,
  transferable,
  reportable,
}: Props) => {
  const ALL_TYPES = ["Tiktok", "Instagram", "Twitter", "Youtube"];
  const [types, setTypes] = useState<string[]>(ALL_TYPES);
  const { data: creatorTags } = useCreatorTags();
  const { data: campaignBudgets } = useCampaignBudgets(data?.id as any);
  const [sortKey, setSortKey] = useState("views");
  const [sortDir, setSortDir] = useState("desc");
  const [tags, setTags] = useState([] as string[]);
  const [selectedTags, setSelectedTags] = useState([] as string[]);
  const [filterDates, setFilterDates] = useState<Value>([null, null]);
  const [posts, setPosts] = useState([] as any[]);

  console.log("sort key", sortKey);
  console.log("sort dir", sortDir);

  const playlists = data?.post?.filter(
    ({ type }) => type === "spotify-playlist"
  );

  const currentTags = Array.from(new Set(data?.tags)).sort();
  if (JSON.stringify(tags) != JSON.stringify(currentTags)) {
    setTags(currentTags);
  }

  useEffect(() => {
    setPosts(
      data?.post
        ?.filter(({ type }) =>
          ["instagram", "tiktok", "twitter", "youtube"].includes(type)
        )
        .filter(
          (post) =>
            !selectedTags.length ||
            post.tags.some((tag) => selectedTags.includes(tag))
        )
        .filter((post) => types.map((i) => i.toLowerCase()).includes(post.type))
        .filter((post) => {
          const postedAt = new Date(post.postedAt);
          let valid = true;
          if (filterDates === null) {
            return true;
          }
          if (filterDates instanceof Date) {
            return postedAt > filterDates;
          }
          if (filterDates[0] !== null && postedAt <= filterDates[0]) {
            valid = false;
          }
          if (filterDates[1] !== null && postedAt >= filterDates[1]) {
            valid = false;
          }
          return valid;
        })
        .sort((a: any, b: any) => {
          const key = sortKey;
          if (key === "postedAt") {
            return (
              (new Date(b[key]).getTime() - new Date(a[key]).getTime()) *
              (sortDir === "asc" ? -1 : 1)
            );
          }
          if (key === "username") {
            if (sortDir === "asc") {
              return b[key]?.localeCompare(a[key]);
            } else {
              return a[key]?.localeCompare(b[key]);
            }
          }
          return (b[key] - a[key]) * (sortDir === "asc" ? -1 : 1);
        })
    );
  }, [sortKey, sortDir]);

  const audio = data?.post.filter(({ type }) =>
    ["instagram-audio", "tiktok-audio"].includes(type)
  );
  const hashtags = data?.hashtags;
  const csvData = useMemo(() => {
    return posts.map((post: any) => {
      const newPost = { ...post };
      delete newPost.id;
      delete newPost.createdAt;
      delete newPost.updatedAt;
      delete newPost.lastScraped;
      delete newPost.lastAttempted;
      delete newPost.posts;
      delete newPost.title;
      delete newPost.avatar;
      delete newPost.thumbnail;
      delete newPost.thumbnailSmall;
      delete newPost.scraperRunId;
      delete newPost.autoScraped;
      delete newPost.originalId;
      return newPost;
    });
  }, [posts]);

  if (!data) {
    return <Loader full={false} />;
  }

  return (
    <>
      <Flex
        id="campaign-header"
        direction="row"
        justify="between"
        gap="4"
        pb="4"
        p="5"
      >
        <Heading size="4">Campaign Report</Heading>
        <Flex gap="5">
          {reportable && (
            <NewCreateReport
              campaign={data}
              tags={selectedTags}
              sortDir={sortDir}
              sortKey={sortKey}
              numPosts={posts?.length}
              types={types}
            />
          )}
          {shareable && (
            <NewShareLink
              id={`${window.location.protocol}//${window.location.hostname}/share/${data.shareCode}`}
            />
          )}
        </Flex>
      </Flex>
      <Tabs.Root defaultValue="posts">
        <Tabs.List size="2">
          <Tabs.Trigger value="posts">
            <Flex direction="row" gap="1">
              Posts
              <Badge>{posts && posts.length}</Badge>
            </Flex>
          </Tabs.Trigger>
          <Tabs.Trigger value="tracking">
            <Flex direction="row" gap="1">
              Tracking
              <Badge>{audio && audio.length}</Badge>
            </Flex>
          </Tabs.Trigger>
          {/* TODO: Fix/finish post drafts feature */}
          {/* <Tabs.Trigger value="postdrafts">
            <Flex direction="row" gap="1">
              Post Drafts
              <Badge>{data.postDrafts && data.postDrafts.length}</Badge>
            </Flex>
          </Tabs.Trigger> */}
          {editable && (
            <Tabs.Trigger value="creators">
              <Flex direction="row" gap="1">
                Creators
                <Badge>{data.budgets && data.budgets.length}</Badge>
              </Flex>
            </Tabs.Trigger>
          )}
          <Tabs.Trigger value="playlists">
            <Flex direction="row" gap="1">
              Playlists
              <Badge>{playlists && playlists.length}</Badge>
            </Flex>
          </Tabs.Trigger>
          <Tabs.Trigger value="settings">Settings</Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value="posts">
          <Flex direction="column" gap="5" pt="4">
            <Flex className="box" direction="column" p="5" gap="3">
              <Heading size="4">Post Analytics</Heading>
              <Flex direction="row" gap="3" pb="2">
                <DateFilter
                  setFilterDates={setFilterDates}
                  filterDates={filterDates}
                />
                <NewMultiSelect
                  transparent
                  style={{ width: 130, marginRight: 10 }}
                  setSelected={setSelectedTags}
                  title="Tags"
                  selected={selectedTags}
                  options={Array.from(new Set(data?.tags)).toSorted() as any}
                />
                <NewMultiSelect
                  transparent
                  style={{ width: 140, marginRight: 10 }}
                  setSelected={setTypes}
                  title="Platform"
                  selected={types}
                  options={ALL_TYPES}
                />
              </Flex>
              <Flex direction="row" gap="2" justify="between">
                <MobileTotals
                  title="Views"
                  value={formatNumber(aggregate(posts, "views"))}
                />
                <MobileTotals
                  title="Likes"
                  value={formatNumber(aggregate(posts, "likes"))}
                />
              </Flex>
              <Flex direction="row" gap="2" justify="between">
                <MobileTotals
                  title="Shares"
                  value={formatNumber(aggregate(posts, "shares"))}
                />
                <MobileTotals
                  title="Comments"
                  value={formatNumber(aggregate(posts, "comments"))}
                />
              </Flex>
            </Flex>
            <Flex className="box" direction="column" gap="5" p="5">
              <Flex direction="row" justify="between">
                <Heading size="4">Posts</Heading>
                <Flex direction="row" gap="2">
                  <EditSort
                    sortKey={sortKey}
                    setSortKey={setSortKey}
                    sortDir={sortDir}
                    setSortDir={setSortDir}
                  />
                  <CSVLink data={csvData} filename={`report.csv`}>
                    <Button variant="outline">
                      <Flex direction="row" align="center" gap="1">
                        <IconDownload className="icon" size={"18px"} /> CSV
                      </Flex>
                    </Button>
                  </CSVLink>
                  {editable && (
                    <NewMassImport
                      type="post"
                      mutate={mutate}
                      campaignId={data.id}
                    />
                  )}
                </Flex>
              </Flex>
              <MobilePostsListView
                fullPosts={posts || []}
                mutate={mutate}
                editable={editable}
              />
            </Flex>
          </Flex>
        </Tabs.Content>

        <Tabs.Content value="tracking">
          <Flex direction="column" pt="4" gap="3">
            <Flex direction="row" justify="between" pl="1">
              <Heading size="4">Tracking - Sounds & Hashtags</Heading>
              {editable && (
                <MassPostImport
                  type="sounds"
                  mutate={mutate}
                  campaignId={data.id}
                />
              )}
            </Flex>
            <Flex className="box" direction="column" p="5">
              <SoundsTab
                audio={audio || []}
                hashtags={hashtags || []}
                mutate={mutate}
                editable={editable}
              />
            </Flex>
          </Flex>
        </Tabs.Content>

        <Tabs.Content value="postdrafts">
          <Flex direction="column" pt="4" gap="3">
            <Flex direction="row" justify="between" pl="1">
              <Heading size="4">Post Drafts</Heading>
              {editable && <NewAddDraft mutate={mutate} campaignId={data.id} />}
            </Flex>
            <Flex className="box" direction="column" p="5">
              <DraftsTab
                drafts={data.postDrafts}
                mutate={mutate}
                editable={editable}
                id={data.id}
              />
            </Flex>
          </Flex>
        </Tabs.Content>

        {editable && (
          <Tabs.Content value="creators">
            <Flex direction="column" pt="4" gap="2">
              <Flex direction="row" justify="between" px="5">
                <Heading size="4">Creators</Heading>
                <NewAddBudget mutate={mutate} campaignId={data.id} />
              </Flex>
              <Flex direction="row" gap="3" px="3">
                <NewMultiSelect
                  transparent
                  style={{ width: 140, marginRight: 10 }}
                  setSelected={setTypes}
                  title="Platform"
                  selected={types}
                  options={ALL_TYPES}
                />
                <NewMultiSelect
                  transparent
                  style={{ width: 150, marginRight: 10 }}
                  setSelected={setSelectedTags}
                  title="Post Formats"
                  selected={selectedTags}
                  options={
                    Array.from(
                      (
                        new Set(creatorTags?.map((t) => t.id)) as any
                      ).intersection(
                        new Set(campaignBudgets?.map((b) => b.creatorTypeId))
                      )
                    )
                      .map((b) => creatorTags?.find((t) => t.id === b)?.tag)
                      .toSorted() as any
                  }
                />
                {selectedTags.map((tag) => {
                  return (
                    <Badge radius="large" key={tag} mr="1">
                      {tag}
                      <IconButton
                        variant="ghost"
                        onClick={() =>
                          setSelectedTags(
                            [...selectedTags].filter((t) => t !== tag)
                          )
                        }
                      >
                        <IconX className="icon-accent" height="10px" />
                      </IconButton>
                    </Badge>
                  );
                })}
              </Flex>
              <CreatorsTab
                platforms={types}
                campaign={data}
                mutate={mutate}
                selectedTags={selectedTags}
              />
            </Flex>
          </Tabs.Content>
        )}

        <Tabs.Content value="playlists">
          <Flex direction="column" pt="4">
            <Flex className="box" p="5">
              <Playlists
                fullPlaylists={playlists}
                spotifyPlaylists={JSON.parse(data?.song?.playlists || "{}")}
                style="mobile"
              />
            </Flex>
          </Flex>
        </Tabs.Content>

        <Tabs.Content value="linkpages"></Tabs.Content>

        <Tabs.Content value="settings">
          <Flex overflowX="scroll">
            <SettingsTab
              transferable={transferable}
              campaign={data}
              mutate={mutate}
              mobile
            />
          </Flex>
        </Tabs.Content>
      </Tabs.Root>
    </>
  );
};
export default memo(MobileAdvancedView);
